import React, { useEffect } from 'react';
import Typed from 'typed.js';

function TypingIntro() {
  useEffect(() => {
    const typed = new Typed(".auto-type span", {
      strings: [
        "I'm a Problem Solver",
        "I'm a Team Player",
        "I'm a Leader",
        //"I'm a Thinker",
        //"I'm a Doer",
        "I'm a Communicator",
        "I'm a Collaborator",
        //"I'm a Motivator",
        "I'm a Mentor",
        "I'm a Full Stack Developer"
      ],
      startDelay: 1000,
      typeSpeed: 50,
      backSpeed: 10,
      cursorChar: '|',
      backDelay: 2000,
      onComplete: () => {
        document.querySelector('.typed-cursor').remove();
        document.querySelector('.d-csv').style.display = 'block';
      },
    });
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div id="typewriter" className="mx-auto flex flex-col w-full min-h-[300px]">
      <p className="auto-type-my">My Name is</p>
      <h1 className="text-[#fff] text-center  text-[50px] md:text-8xl ">JEFF BALICKI</h1>
      <div className="auto-type"><span></span></div>
    </div>
  );
}

export default TypingIntro;