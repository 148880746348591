import React, { useState } from 'react'; 
import {
  FaBars,
  FaTimes,
} from 'react-icons/fa';
import { Link } from 'react-scroll';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className='fixed w-full h-[80px] top-[0px] z-[70] flex justify-between items-center px-4 bg-[#3d3a4b] text-white drop-shadow-[0_5px_5px_rgba(0,0,0,0.25)]'>
      {/* menu */}
      <ul className='hidden w-[80%] ml-auto mr-auto columns-6 flex justify-around  md:flex uppercase '>
        
        <li>
          <Link className="cursor-pointer hover:text-[#fe3c3a]" to='home' smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li>
          <Link className="cursor-pointer hover:text-[#fe3c3a]" to='about' smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li>
          <Link className="cursor-pointer hover:text-[#fe3c3a]" to='skills' smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li>
          <Link className="cursor-pointer hover:text-[#fe3c3a]" to='experience' smooth={true} duration={500}>
          Experience
          </Link>
        </li>
        <li>
          <Link className="cursor-pointer hover:text-[#fe3c3a]" to='works' smooth={true} duration={500}>
          Work      
          </Link>
        </li>
        <li>
          <Link className="cursor-pointer hover:text-[#fe3c3a]" to='contact' smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>

      {/* Hamburger */}
      <div onClick={handleClick} className='md:hidden z-[10]'>
        {!nav ? <FaBars /> : <FaTimes />}
      </div>
      <h2 className="text-center md:invisible text-2xl font-bold text-[#ffffff]">JB</h2>
      {/* Mobile menu */}
      <ul
        className={`z-[10] transition-all duration-500 absolute top-[75px] w-full h-screen bg-[#0a192f] flex flex-col  items-center bg-[#3d3a4b] overflow-x-auto ${
          !nav
            ? 'left-[-100%] '
            : 'left-[0%]  '
          }`}
      >  
    
        <li className='py-6 text-2xl'>
          <Link onClick={handleClick} to='home' smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className='py-6 text-2xl'>
          {' '}
          <Link onClick={handleClick} to='about' smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className='py-6 text-2xl'>
          {' '}
          <Link onClick={handleClick} to='skills' smooth={true} duration={500}>
          Skills
          </Link>
        </li>
        <li className='py-6 text-2xl'>
          {' '}
          <Link onClick={handleClick} to='experience' smooth={true} duration={500}>
          Experience
          </Link>
        </li>
        <li className='py-6 text-2xl'>
          {' '}
          <Link onClick={handleClick} to='works' smooth={true} duration={500}>
          Work
          </Link>
        </li>
        <li className='py-6 text-2xl'>
          {' '}
          <Link onClick={handleClick} to='contact' smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>
      
    </div>
  );
};
export default Navbar;