import React from 'react'
import Education from './Education';
import Jobs from './Jobs';



const Experience = () => {
  return (
    <div
      name="experience"
      id="experience"
      className="w-full overflow-y-hidden bg-[#3d3a4b] text-[#4d4d4d] scroll-pl-[100px] h-auto pt-[40px] pb-[100px] mx-auto"
    >
      <h2 className="text-center text-[40px] md:text-[50px] font-bold text-white py-[70px]">My Experience </h2>
      <div className="w-full">
      <Jobs/>
      </div>
      <h2 className="text-center text-[30px] md:text-[50px] font-bold text-white  py-[70px]">My Education </h2>
      <div className="w-full">
       <Education/>
      </div>
    </div>
  );
};


export default Experience