import React, { useEffect, useState  } from 'react';


const Education = () => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const scrollThreshold = 10; // Adjust this value to control sensitivity

  useEffect(() => {
    const elements = document.querySelectorAll('.scrollBorder');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const scrollDifference = currentScrollTop - lastScrollTop;
          const isScrollingDown = scrollDifference > scrollThreshold;
          const isScrollingUp = scrollDifference < -scrollThreshold;

          if (entry.isIntersecting) {
            if (isScrollingDown) {
              entry.target.classList.add('filled-border-top-to-bottom');
              entry.target.classList.remove('filled-border-bottom-to-top');
            } else if (isScrollingUp) {
              entry.target.classList.add('filled-border-bottom-to-top');
              entry.target.classList.remove('filled-border-top-to-bottom');
            }
          } else {
            entry.target.classList.remove('filled-border-top-to-bottom', 'filled-border-bottom-to-top');
          }

          if (Math.abs(scrollDifference) > scrollThreshold) {
            setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop); // For Mobile or negative scrolling
          }
        });
      },
      {
        threshold: 0.9, // Adjust the threshold as needed
      }
    );

    elements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, [lastScrollTop]);


const educationData = [
  {
    title: 'Interactive Media Specialist', company: 'Fanshawe College', year: '2012',
    experiences: [
      "Deans's honours list first, second semester."
    ]
  },
  {
    title: 'Multi-Media Design and Production', company: 'Fanshawe College', year: '2009 - 2011',
    experiences: [
      "Deans's honours list first, second, and fourth semester."
    ]
  },
];

  return (
     <div className="flex flex-col lg:flex-row max-w-[1344px] mx-auto pl-[26px]  lg:px-[20px] lg:px-[70px]">
     {educationData.map((item, index) => (
        <div key={index} className="flex flex-col text-white py-[20px] md:pl-[20px] w-full lg:w-[50%]">
          <div className="flex flex-col justify-between items-end pr-[30px] w-full">
            <h3 className="scrollBorder text-lg font-bold uppercase w-[100%] lg:w-[100%] mx-auto text-center pb-[10px]">
              {item.title}
            </h3>
            <div className="flex flex-row flex-wrap w-[100%] lg:w-[70%] mx-auto justify-between mt-[20px]">
              <p className="text-base">{item.company}</p>
              <p className="text-base">{item.year}</p>
            </div>
          </div>
          <div className="flex flex-col justify-center items-start w-[100%] lg:w-[80%] mx-auto pt-[10px] px-[10px] lg:px-[40px]">
            <ul className="list-disc">
              {item.experiences && item.experiences.map((experience, expIndex) => (
                <li key={expIndex} className="text-sm">{experience}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Education;