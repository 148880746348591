import React from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import ContactForm from './ContactForm'; 
import mapImage from '../assets/map.webp';




const Contact = () => {
  return (
    <LazyLoadComponent>
    <div
      name="contact"
      id="contact"
      className="w-full overflow-y-hidden bg-white text-[#4d4d4d] md:bg-fixed min-h-[900px]  mx-auto bg-cover flex items-center justify-center mt-[80px] z-8"
      style={{ backgroundImage: `url(${mapImage})` }}
    >
      <div className="w-[95%] mb:w-[80%] min-h-[600px] pb-[90px] mx-auto bg-[#3d3a4b] max-w-[1344px] ">
        <h2 className="text-center text-[40px] md:text-[50px] font-bold text-white pt-[70px] pb-[30px]">Contact Me</h2>
        <div className="flex flex-col mx-auto ">
         <ContactForm />
        </div>
      </div>
    </div>
    </LazyLoadComponent>
  );
};


export default Contact