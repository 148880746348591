import { useState, useEffect } from 'react';

const skills = [
  { label: 'WordPress', percent: 95 },
  { label: 'PHP', percent: 90 },
  { label: 'JavaScript', percent: 85 },
  { label: 'HTML & CSS', percent: 90 },
  { label: 'MySQL', percent: 75 },
];

function SkillsBars() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, {
      rootMargin: '-300px 0px'
    });
  

    observer.observe(document.getElementById('myskills'));

    return () => observer.disconnect();
  }, []);

  return (
    <div id="myskills">
      {skills.map((skill, index) => (
        <div className="skill" key={index}>
          <div className="skill-info">
            <span className="skill-label">{skill.label}</span>
            <span className="skill-percent">{skill.percent}%</span>
          </div>
          <div className="skill-meter">
            <div
               className={`skill-range ${skill.label.replace(/[\s&]+/g, '')}`}
              style={{
                width: isVisible ? `${skill.percent}%` : '0%',
                transition: 'width 2s',
              }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SkillsBars;