import React from 'react'
import { ReactComponent as FrontendIcon }from '../assets/FRONTEND.svg'; 
import { ReactComponent as BackendIcon }from '../assets/BACKEND.svg'; 
import { ReactComponent as ServerIcon }from '../assets/SERVER.svg';
import { ReactComponent as TeamIcon }from '../assets/TEAM.svg'; 
import { ReactComponent as ApiIcon }from '../assets/API.svg'; 
import { ReactComponent as WordpressIcon }from '../assets/WORDPRESS.svg';

const SkillsInfo = ({ title, text, icon }) => (
  <div className="flex flex-col justify-start items-center md:p-10 w-full lg:w-1/3">
    <div className="bg-red-500 rounded-full h-[114px] w-[114px] mb-[10px] flex justify-evenly items-center text-white">{icon}</div>
    <div  className="text-center m-[20px]">
      <p className="text-[25px] font-bold mb-[10px] uppercase">{title}</p>
      <p className="text-base leading-8">{text}</p>
    </div>
  </div>
);

const skillsInfo = [
  { title: 'Frontend ', text: 'Creating responsive, user-friendly interfaces, with eye-catching animations, while ensuring seamless user experiences across various devices and browsers.',  icon:  <FrontendIcon /> },
  { title: 'Backend', text: 'Designing and maintaining server-side logic, optimizing database performance, and ensuring efficient data processing and security in backend development.', icon: <BackendIcon />},
  { title: 'Server Management', text: 'Troubleshooting, optimizing, and securing server clusters, for stability and performance. Identifying bottlenecks, resolving issues, and maintaining up-to-date security protocols.', icon: <ServerIcon /> },
  { title: 'WordPress', text: 'Developing and maintaining custom WordPress themes and plugins, tailoring solutions to meet specific client needs and project requirements.', icon: <WordpressIcon />},
  { title: 'API Services', text: 'Constructing and maintaining APIs, facilitating smooth communication between websites, mobile apps, and SaaS platforms.', icon: <ApiIcon />},
  { title: 'Team Lead', text: 'Leading development teams, managing projects, and collaborating with clients to deliver high-quality web solutions on time and budget.', icon: <TeamIcon />},
];

const Skills = () => {
  return (
    <div
      name="skills"
      id="skills"
      className="w-full overflow-y-hidden  bg-[#ffffff] text-[#4d4d4d]  min-h-[1026px] pb-[70px] pt-[40px] scroll-pl-[100px]  max-w-[1344px] mx-auto"
    >
    <h2 className="text-center text-[40px] md:text-[50px] font-bold  py-[70px]">MY SKILLS </h2>
    <div className="flex flex-col items-center w-full h-1/3">
        <div className="flex w-full flex-col lg:flex-row justify-center">
          {skillsInfo.slice(0, 3).map(info => <SkillsInfo {...info} key={info.title} />)}
        </div>
        <div className="flex w-full  flex-col lg:flex-row  justify-center">
          {skillsInfo.slice(3).map(info => <SkillsInfo {...info} key={info.title} />)}
        </div>
      </div>
    </div>
  );
};


export default Skills