import About from './components/About';
import Contact from './components/Contact';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Experience from './components/Experience';
import Works from './components/Works';
import Skills from './components/Skills';

import './App.css';

function App() {
  return (
    <div className='overflow-y-hidden max-w-full'>
    <Navbar />
    <Home />
    <About />
    <Skills />
    <Experience />
    <Works />
    <Contact />
  </div>
  );
}

export default App;
