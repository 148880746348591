import { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const SERVICE_ID = "service_fkkpfzf";
const TEMPLATE_ID = "template_jksum06";
const PUBLIC_KEY = "tHy4e6X49ZvXb4XAs";
const RECAPTCHA_SITE_KEY = "6LdKsycqAAAAADUYmYk9Sk_zVaHBUqScvjNpy4_5"; // Replace with your reCAPTCHA site key

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const [alertInfo, setAlertInfo] = useState({
    display: false,
    message: '',
    type: '',
  });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formRef = useRef();

  // Shows alert message for form submission feedback
  const toggleAlert = (message, type) => {
    setAlertInfo({ display: true, message, type });

    // Hide alert after 5 seconds
    setTimeout(() => {
      setAlertInfo({ display: false, message: '', type: '' });
    }, 5000);
  };

  const handleOnSubmit = async (data) => {
    if (!executeRecaptcha) {
      toggleAlert('reCAPTCHA not ready', 'danger');
      return;
    }

    const recaptchaToken = await executeRecaptcha('contact_form');

    if (!recaptchaToken) {
      toggleAlert('Please complete the reCAPTCHA', 'danger');
      return;
    }

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, formRef.current, PUBLIC_KEY)
      .then((result) => {
        console.log('EmailJS result:', result);
        toggleAlert('Thank you for your message!', 'success');
      }, (error) => {
        console.error('EmailJS error:', error);
        toggleAlert('Uh oh. Something went wrong.', 'danger');
      });
    reset();
  };

  return (
    <div className='flex flex-col items-center w-full'>
      <div className='contactForm w-[80%]'>
        <form ref={formRef} className="formContainer" onSubmit={handleSubmit(handleOnSubmit)}>
          {/* Row 1 of form */}
          <div className='flex flex-wrap flex-row'>
            <div className='w-1/2 pr-[20px]'>
              <input
                type='text'
                name='name'
                {...register('name', {
                  required: { value: true, message: 'Please enter your name' },
                  maxLength: {
                    value: 30,
                    message: 'Please use 30 characters or less'
                  }
                })}
                className='form-control formInput w-[100%] my-[15px] p-2 rounded-none'
                placeholder='Name'
              ></input>
              {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
            </div>
            <div className='w-1/2'>
              <input
                type='email'
                name='email'
                {...register('email', {
                  required: true,
                  pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                })}
                className='form-control formInput w-[100%] my-[15px] p-2 rounded-none'
                placeholder='Email address'
              ></input>
              {errors.email && (
                <span className='errorMessage'>Please enter a valid email address</span>
              )}
            </div>
          </div>
          {/* Row 2 of form */}
          <div className='flex flex-wrap w-full'>
            <div className='w-full'>
              <input
                type='text'
                name='subject'
                {...register('subject', {
                  required: { value: true, message: 'Please enter a subject' },
                  maxLength: {
                    value: 75,
                    message: 'Subject cannot exceed 75 characters'
                  }
                })}
                className='form-control formInput w-[100%] my-[15px] p-2 rounded-none'
                placeholder='Subject'
              ></input>
              {errors.subject && (
                <span className='errorMessage'>{errors.subject.message}</span>
              )}
            </div>
          </div>
          {/* Row 3 of form */}
          <div className='flex flex-wrap w-full'>
            <div className='w-full'>
              <textarea
                rows={3}
                name='message'
                {...register('message', {
                  required: true
                })}
                className='form-control formInput  w-[100%] my-[15px] p-2 rounded-none'
                placeholder='Message'
              ></textarea>
              {errors.message && <span className='errorMessage'>Please enter a message</span>}
            </div>
          </div>
          <button className='submit-btn group text-black w-fit px-6 py-3 my-2 flex items-center font-bold  cursor-pointer bg-[#dafd54] mx-auto hover:bg-[#fe3c3a] hover:text-white' type='submit'>
            Submit
          </button>
        </form>
      </div>
      {alertInfo.display && (
        <div
          className={`alert alert-${alertInfo.type} alert-dismissible mt-5`}
          role='alert'
        >
          {alertInfo.message}
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='alert'
            aria-label='Close'
            onClick={() =>
              setAlertInfo({ display: false, message: '', type: '' })
            } 
          ></button>
        </div>
      )}
    </div>
  );
};

const App = () => (
  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
    <ContactForm />
  </GoogleReCaptchaProvider>
);

export default App;