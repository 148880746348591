import React, { useEffect, useState  } from 'react';


const Jobs = () => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const scrollThreshold = 10; // Adjust this value to control sensitivity

  useEffect(() => {
    const elements = document.querySelectorAll('.scrollBorderRight');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const scrollDifference = currentScrollTop - lastScrollTop;
          const isScrollingDown = scrollDifference > scrollThreshold;
          const isScrollingUp = scrollDifference < -scrollThreshold;

          if (entry.isIntersecting) {
            if (isScrollingDown) {
              entry.target.classList.add('filled-border-top-to-bottom');
              entry.target.classList.remove('filled-border-bottom-to-top');
            } else if (isScrollingUp) {
              entry.target.classList.add('filled-border-bottom-to-top');
              entry.target.classList.remove('filled-border-top-to-bottom');
            }
          } else {
            entry.target.classList.remove('filled-border-top-to-bottom', 'filled-border-bottom-to-top');
          }

          if (Math.abs(scrollDifference) > scrollThreshold) {
            setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop); // For Mobile or negative scrolling
          }
        });
      },
      {
        threshold: 0.9,  // Adjust the threshold as needed
      }
    );

    elements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, [lastScrollTop]);



  const experienceData = [
    {
      title: 'Lead Full-Stack Developer', company: 'Tenzing Communications', year: '2016 - 2024',
      experiences: [
        "Engineered API’s between websites and SaaS/websites and mobile apps.",
        "Built customized WordPress themes, and plugins to suit clients' needs.",
        "Maintained and improved server clusters and improved protocols for deploying projects."
      ]
    },
    {
      title: 'Lead Developer', company: 'Moreno Web Design', year: '2014 - 2015',
      experiences: [
        "Participated in the company startup and development of client base.",
        "Developed and maintained multiple companies and charity websites.",
        "Headed sales and development meetings with clients.",
      ]
    },
    {
      title: 'App Developer', company: 'Zepeel inc.', year: '2013',
      experiences: [
        "Collaborated on app redesign, then followed through with the PHP, HTML, and CSS changes.",
        "Developed and implemented a disaster prevention and recovery plan for the app and database.",
        "Created a backend interface for security, billing, stats, and user compliance.",
      ]
    },
    {
      title: 'Web Developer', company: 'Ad Home Creative', year: '2012',
      experiences: [
        "Created new functions for multi-user online applications using M.V.C. style coding",
        "Implemented an integrated campaign monitor for a large e-commerce site.",
        "Responsible for troubleshooting and repair of large online applications built with Codeigniter.",
      ]
    },
  
  ];

  return (
     <div className="flex flex-col max-w-[1344px] mx-auto px-[30px]">
     {experienceData.map((item, index) => (
      <div key={index}  className="flex flex-col lg:flex-row w-full text-white  py-[20px] md:pl-[20px]" >
       <div className="scrollBorderRight min-h-[112px] flex flex-col justify-center items-center lg:items-end w-full lg:w-[30%] pr-[30px] pb-0 lg:pb-8 lg:pb-0 ">
         <h3 className="text-lg font-bold uppercase">{item.title}</h3>
         <p className="text-base">{item.company}</p>
         <p className="text-base">{item.year}</p>
       </div>
       <div className="flex flex-col justify-center items-start px-5 py-5 lg:px-[40px] w-full lg:w-[70%]">
         <ul className="list-disc">
            {item.experiences && item.experiences.map((experience, expIndex) => (
                <li key={expIndex} className="text-sm">{experience}</li>
              ))}
          </ul>
       </div>
     </div>
      ))}
    </div>
  );
};

export default Jobs;