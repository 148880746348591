import React from "react";
import filler from '../assets/jeff-pro.webp';
import SkillsBars from './SkillsBars';
import { ReactComponent as PhoneIcon }from '../assets/phone.svg'; 
import { ReactComponent as LocationIcon }from '../assets/location.svg'; 
import { ReactComponent as EmailIcon }from '../assets/email.svg'
const ContactInfo = ({ title, text, icon }) => (
  <div className="flex justify-start ml-[30px] lg:mx-auto lg:justify-center w-[90%] lg:w-1/3 text-white mt-[40px] lg:mt-0">
    <div className="bg-red-500 rounded-full h-[47px] w-[47px] lg:h-[67px] lg:w-[67px] p-[10px] flex justify-evenly items-center">{icon}</div>
    <div className="flex flex-col justify-start lg:justify-center ">
      <p className="ml-[20px] text-lg font-bold uppercase">{title}</p>
      <p className="ml-[20px] text-sm">{text}</p>
    </div>
  </div>
);

const contactInfos = [
  { title: 'Phone', text: '519-719-5333', icon: <PhoneIcon />},
  { title: 'Location', text: 'London, Ontario CA.', icon: <LocationIcon />},
  { title: 'Email', text: 'jeffmbalicki@gmail.com', icon: <EmailIcon />},
];

const About = () => {
  return (
    <div
      name="about"
      id="about"
      className="w-full overflow-y-hidden bg-[#ffffff] scroll-pl-[100px] text-gray-300 min-h-[800px] pt-[40px]"
    >
      <div className="w-full flex  bg-[#ffffff] items-end text-gray-300 min-h-[600px] max-w-[1544px] mx-auto   px-[30px] lg:px-[70px] lg:pt-[0px] pt-[70px]">
        <div className="flex flex-wrap  md:flex-coll g:flex-row w-full justify-between">
          <div className="flex flex-col justify-center lg:pr-[40px]  md:w-full lg:w-1/3 order-1  md:order-1 lg:order-1">
            <h2 className="lg:text-right text-center  sm:text-center  text-4xl font-bold text-[#000000]">JEFF BALICKI</h2>
            <p className="lg:text-right text-center sm:text-center text-2xl  text-[#fe3c3a]">FULL STACK DEVELOPER</p>
            <p className="lg:text-right text-center sm:text-center  text-[#494949] leading-8 mt-[10px]">
            I am an organized, detail-oriented, and reliable professional with a proven ability to adapt to new environments and take on unfamiliar tasks with confidence and successful outcomes. My extensive experience in the service industry has honed my strong interpersonal and communication skills. I have been recognized as a top performer in my workplaces, as well as in my studies.
            </p>
          </div>
          <div className="flex flex-col justify-end w-full lg:w-1/3 order-3  md:order-3 lg:order-2">
            <img
              src={filler}
              alt="my profile"
              className=" max-w-[600px] lg:max-w-[100%] mx-auto w-full flex flex-col justify-center" height ="468px" width="458px"
            />
          </div>
          <div className="flex flex-col justify-center pl-[30px] w-full lg:w-1/3 order-2  md:order-2 lg:order-3">
            <SkillsBars />
          </div>
        </div>
      </div>
      <div className="flex items-center w-full bg-[#3d3a4b]  min-h-[200px] overflow-y-hidden max-w-[100%]">
        <div className="flex w-full flex-col lg:flex-row justify-start  max-w-[1344px]  mx-auto pb-[40px] lg:pb-[0px] overflow-y-hidden ">
          {contactInfos.map(info => <ContactInfo {...info} key={info.title} />)}
        </div>
      </div>
    </div>

  );
};
export default About;