import React from 'react';

import bgImage from '../assets/header.webp';
import Res from '../assets/JeffBalicki.pdf';
import TypingIntro from './TypingIntro';

const Home = () => {
  return (
    <div
    name="home"
    className="h-screen min-h-[600px] overflow-y-hidden w-full bg-cover  md:bg-fixed bg-center bg-no-repeat relative flex items-center justify-center mt-[0px] md:mt-[80px] z-8"
    style={{ backgroundImage: `url(${bgImage})`}}
  >
    <div className="mx-auto flex flex-col items-center justify-center h-full md:flex-row">

      <div className="flex flex-col justify-center h-full w-full">
      <TypingIntro />
        <div className="flex pt-[50px] justify-center w-full min-h-[64px]">
        <a href={Res} target="_blank"  className="group text-black w-fit px-6 py-3 my-2 flex items-center font-bold  cursor-pointer bg-[#dafd54] d-csv hover:bg-[#fe3c3a] hover:text-white"  rel="noindex, nofollow">
            Download CV
          </a>
        </div>
      </div>
      <div>
       
      </div>
    </div>
  </div>
  );
};
export default Home;